<template>
    <div class="purchase-order-lines-editor-content">
        <h3>Purchase Order Lines</h3>
        <div class="purchase-order-lines-editor-content-commands">
            <button @click="addNewPurchaseOrderLine">New Line</button>
        </div>

        <div class="purchase-order-lines-editor-content-lines">
            <div class="purchase-order-lines-editor-content-lines-list">
                <ul>
                    <li v-for="(line, index) in purchaseOrderLines" :key="index"
                        @click="selectedPurchaseOrderLineIndex = index"
                        :class="[index == selectedPurchaseOrderLineIndex ? 'purchase-order-lines-editor-content-lines-selected': '']">
                        <div>- Line {{line.lineNumber}} - Item {{line.vendorItemCode}} - Expected Quantity {{line.expectedQuantity}}</div>
                    </li>
                </ul>
            </div>
            <div class="purchase-order-lines-editor-content-line-form" v-if="selectedPurchaseOrderLineIndex > -1">
                <PurchaseOrderLineForm :purchaseOrderLine="selectedPurchaseOrderLine" :selectableVendorItemCdms="selectableVendorItemCdms" />
            </div>
        </div>
    </div>    
</template>

<style scoped>
.purchase-order-lines-editor-content {
    display: flex;
    flex-direction: column;
}

.purchase-order-lines-editor-content-commands {
    display: flex;
    flex-direction: row;
}

.purchase-order-lines-editor-content-lines {
    display: flex;
    flex-direction: row;
}   

.purchase-order-lines-editor-content-lines-list {
    display: flex;
    flex-direction: column;
    border: 1px black solid;
    width: 35em;
}

.purchase-order-lines-editor-content-lines-list > ul {
    list-style-type: none;
    width: 100%;
    text-align: left;
    padding: 0;
}

.purchase-order-lines-editor-content-lines-list > ul > li > div {
    padding: 0.5em;
    cursor: pointer;
}


.purchase-order-lines-editor-content-lines-selected {
    font-weight: bold;
}


</style>
<script lang="js" src="./PurchaseOrderLinesEditor.js" />