import PurchaseOrderLineForm from "./PurchaseOrderLineForm.vue";

export default {
    name: "PurchaseOrderLinesEditor",
    components: {
        PurchaseOrderLineForm,
    },
    props: [
        'purchaseOrderLines',
        'selectableVendorItemCdms'
    ],
    data() {
        return {
            selectedPurchaseOrderLineIndex: -1,
        };
    },
    computed: {
        selectedPurchaseOrderLine: function() {
            if (this.selectedPurchaseOrderLineIndex > -1) {
                return this.purchaseOrderLines[this.selectedPurchaseOrderLineIndex];
            }
            else {
                return undefined;
            }
        }
    },
    methods: {
        addNewPurchaseOrderLine() {
            this.$emit('onNewLineAddRequested');
        }
    }
}