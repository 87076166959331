import AddressForm from "../master-data-gen/AddressForm.vue";


export default {
    name: "VendorCdmsList",
    components: {
        AddressForm,
    },
    props: [
        'vendorCdms'
    ],
    data() {
        return {
            selectedVendorId: ""
        };
    },
    computed: {
        vendors: function() {
            return this.vendorCdms;
        } 
    },
    methods: {
        onVendorCdmSelected() {
            this.$emit("onVendorCdmSelected", this.vendorCdms.find(vendorCdm => vendorCdm.VendorId == this.selectedVendorId));
        }   
    }
}