<template>
    <div class="vendor-cdm-list-content">
        <select class="vendor-cdm-list-content-select" 
            v-model="selectedVendorId" @change="onVendorCdmSelected">
            <option v-for="vendor in vendors" :key="vendor.VendorId">{{vendor.VendorId}}</option>
        </select>
    </div>    
</template>

<style scoped>
.vendor-cdm-list-content {
    display: flex;
    flex-direction: column;
}

.vendor-cdm-list-content-select {
    padding: 0.2em;
    width: 25em;
}
</style>
<script lang="js" src="./VendorCDMsList.js" />