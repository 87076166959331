import AddressForm from "../master-data-gen/AddressForm.vue";


export default {
    name: "VendorItemCdmsSelector",
    components: {
        AddressForm,
    },
    props: [
        'vendorItemCdms'
    ],
    data() {
        return {
            selectedVendorItemId: ""
        };
    },
    computed: {
        vendorItems: function() {
            return this.vendorItemCdms;
        } 
    },
    watch: {
        vendorItemCdms: function() {
            this.selectedVendorItemId = "";
        }
    },
    methods: {
        onVendorItemCdmSelected() {
            this.$emit("onVendorItemCdmSelected", 
                this.vendorItemCdms.find(vendorItemCdm => vendorItemCdm.VendorItemId == this.selectedVendorItemId)
            );
        }   
    }
}