<template>
    <div class="purchase-order-line-form-content">
        <div>
            <div class="purchase-order-line-form-field">
                <span>Purchase Order Line Number</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.lineNumber"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Vendor Item</span>
                <VendorItemCDMsSelector class="purchase-order-line-form-field-vendor-item" 
                    :vendorItemCdms="selectableVendorItemCdms" 
                    @onVendorItemCdmSelected="onVendorItemCdmSelected" />
            </div>                
            <div class="purchase-order-line-form-field">
                <span>Item Code</span>
                <input disabled type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.itemCode"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Item External Reference</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.itemExternalReferences"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Vendor Item Code</span>
                <input disabled type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.vendorItemCode"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Vendor Item External Reference</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.vendorItemExternalReference"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Vendor Item Description</span>
                <input disabled type="text" class="purchase-order-line-form-field-textbox"
                    v-if="purchaseOrderLine.vendorItemDescription"
                    v-model="purchaseOrderLine.vendorItemDescription[0].shortDescription"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Expected Quantity</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.expectedQuantity"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Purchase Quantity</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.purchaseQuantity"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Purchase UOM</span>
                <select class="purchase-order-line-form-field-select"
                    v-model="purchaseOrderLine.purchaseUomCode"
                    >
                    <option v-for="uom in selectableItemPurchaseUoms" 
                        :key="uom.VendorUomCode" :value="uom.VendorUomCode">
                        {{ uom.VendorUomCode }}</option>
                </select>
            </div>
            <div class="purchase-order-line-form-field">
                <span>Purchase UOM Code</span>
                <input disabled type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.purchaseUomCode"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Warehouse Code</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.warehouseCode"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Warehouse Global Location Number</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.warehouseGlobalLocationNumber"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Requested Delivery Date</span>
                <input type="date" class="purchase-order-form-field-date"
                    v-model="purchaseOrderLine.requestedDeliveryDate"
                    />
            </div>            
            <div class="purchase-order-line-form-field">
                <span>Landed Cost in Stocking UOM</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.landedCostInStockingUom"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Net Unit Cost</span>
                <input type="text" class="purchase-order-line-form-field-textbox"
                    v-model="purchaseOrderLine.netUnitCost"
                    />
            </div>
            <div class="purchase-order-line-form-field">
                <span>Net Unit Cost Overriden</span>
                <input type="checkbox" class="purchase-order-line-form-field-checkbox"
                    v-model="purchaseOrderLine.netUnitCostOverriden"
                    />
            </div>
        </div>
    </div>    
</template>

<style scoped>
.purchase-order-line-form-content {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

.purchase-order-line-form-content-header {
    display: flex;
    flex-direction: column;
}

.purchase-order-line-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.purchase-order-line-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.purchase-order-line-form-field span {
    width: 25em;
    text-align: left;
}

.purchase-order-line-form-field-vendors {
    width: auto;    
}

.purchase-order-line-form-field-checkbox {
    width: auto;    
}

.purchase-order-line-form-field-textbox {
    width: 30em;    
    padding: 0.2em;
}

.purchase-order-line-form-field-date {
    width: 10em;    
    padding: 0.2em;
}

.purchase-order-line-form-field-select {
    width: 30.7em;    
    padding: 0.2em;
}

.purchase-order-line-form-field-vendor-item {
    width: 25.6em;    
}

</style>
<script lang="js" src="./PurchaseOrderLineForm.js" />