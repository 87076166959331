<template>
    <div class="purchase-order-generator-content">
        <h3>New Purchase Order</h3>
        <div>Loading: {{status.loading}}</div>
        <div>Message: {{status.message ? status.message : ""}}</div>
        <div>Api Success: {{status.data && status.data.success ? status.data.success: ""}}</div>
        <div>Api message: {{status.data && status.data.message}}</div>
        
        <div class="purchase-order-generator-content-commands">
            <button @click="loadCdmModel">Load Cdm Model</button>
        </div>
        <PurchaseOrderForm :purchaseOrder="newPurchaseOrder" :vendorCdms="vendorCdms" />
        <PurchaseOrderLinesEditor 
            :purchaseOrderLines="newPurchaseOrder.lines" 
            :selectableVendorItemCdms="vendorItemCdms"
            @onNewLineAddRequested="onNewLineAddRequested" />
    </div>
</template>

<style scoped>
.purchase-order-generator-content {
    display: flex;
    flex-direction: column;
}

.purchase-order-generator-content-commands {
    padding: 1em;
    display: flex;
    flex-direction: row;
}
</style>

<script lang="js" src="./PurchaseOrderGenerator.js" />