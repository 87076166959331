<template>
    <div class="purchase-order-form-content">
        <div class="purchase-order-form-content-header">
            <div>
                <div class="purchase-order-form-field">
                    <span>System Managed</span>
                    <select
                        class="purchase-order-form-field-select"
                        v-model="purchaseOrder.systemManagedType"
                        >
                        <option disabled value="">Please Select One</option>
                        <option value="TECSYS">TECSYS</option>
                        <option value="ERP">ERP</option>
                    </select>
                </div>
                <div class="purchase-order-form-field">
                    <span>Purchase Order Number</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.purchaseOrderNumber"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>External Reference</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.externalReference"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Vendor:</span>
                    <VendorCDMsList class="purchase-order-form-field-vendors" v-if="vendorCdms" 
                        :vendorCdms="vendorCdms" @onVendorCdmSelected="onVendorCdmSelected"/>
                </div>
                <div class="purchase-order-form-field">
                    <span>Organization Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.organizationCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Vendor Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.vendorCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Vendor External Reference</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.vendorExternalReference"
                        />
                </div>
                
                <div class="purchase-order-form-field">
                    <span>Buyer Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.buyerCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Buyer Employee Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.buyerEmployeeCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Buyer Name</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.buyerName"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Currency Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.currencyCode"
                        />
                </div>            
                <div class="purchase-order-form-field">
                    <span>Order Source</span>
                    <select
                        class="purchase-order-form-field-select"
                        v-model="purchaseOrder.orderSource"
                        >
                        <option disabled value="">Please Select One</option>
                        <option value="REGULAR">Regular</option>
                        <option value="SUPPLY_ORDER">Supply Order</option>
                        <option value="EXTERNAL">External</option>
                    </select>
                </div>
                <div class="purchase-order-form-field">
                    <span>Purchase Order Date</span>
                    <input type="date" class="purchase-order-form-field-date"
                        v-model="purchaseOrder.purchaseOrderDate"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Purchase Order Status</span>
                    <select 
                        class="purchase-order-form-field-select"
                        v-model="purchaseOrder.purchaseOrderStatus"
                        >
                        <option disabled value="">Please Select One</option>
                        <option value="NEW">NEW</option>
                        <option value="ISSUED">Issued</option>
                        <option value="CANCELED">Canceled</option>
                        <option value="FINISHED">Finished</option>
                    </select>
                </div>
                <div class="purchase-order-form-field">
                    <span>Purchase Order Type</span>
                    <select
                        class="purchase-order-form-field-select"
                        v-model="purchaseOrder.purchaseOrderType"
                        >
                        <option disabled value="">Please Select One</option>
                        <option value="REGULAR">Regular</option>
                        <option value="BILL_ONLY">Bill Only</option>
                        <option value="BILL_AND_REPLACE">Bill and Replace</option>
                    </select>
                </div>
                <div class="purchase-order-form-field">
                    <span>Requested Delivery Date</span>
                    <input type="date" class="purchase-order-form-field-date"
                        v-model="purchaseOrder.requestedDeliveryDate"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Division Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.divisionCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Division External Reference</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.divisionExternalReference"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Free On-board Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.freeOnBoardCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Payment Term Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.paymentTermCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Ship Via Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.shipViaCode"
                        />
                </div>
                
                <div class="purchase-order-form-field">
                    <span>ASN Required</span>
                    <input type="checkbox" class="purchase-order-form-field-checkbox"
                        v-model="purchaseOrder.asnRequired"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Contains Special Order</span>
                    <input type="checkbox" class="purchase-order-form-field-checkbox"
                        v-model="purchaseOrder.containsSpecialOrder"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Drop Shipment</span>
                    <input type="checkbox" class="purchase-order-form-field-checkbox"
                        v-model="purchaseOrder.dropShipment"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Source System Trusted</span>
                    <input type="checkbox" class="purchase-order-form-field-checkbox"
                        v-model="purchaseOrder.sourceSystemTrusted"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Supply Site Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.supplySiteCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Supply Site Address Code</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.supplySiteAddressCode"
                        />
                </div>
                <div class="purchase-order-form-field">
                    <span>Supply Site Address Name</span>
                    <input type="text" class="purchase-order-form-field-textbox"
                        v-model="purchaseOrder.supplySiteAddressName"
                        />
                </div>
            </div>
            <AddressForm title="Supply Site Address" :address="purchaseOrder.supplySiteAddress" />            
        </div>
    </div>    
</template>

<style scoped>
.purchase-order-form-content {
    display: flex;
    flex-direction: column;
}

.purchase-order-form-content-header {
    display: flex;
    flex-direction: column;
}

.purchase-order-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.purchase-order-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.purchase-order-form-field span {
    width: 30%;
    text-align: left;
}

.purchase-order-form-field-vendors {
    width: auto;    
}

.purchase-order-form-field-checkbox {
    width: auto;    
}

.purchase-order-form-field-textbox {
    width: 30em;    
    padding: 0.2em;
}

.purchase-order-form-field-date {
    width: 10em;    
    padding: 0.2em;
}

.purchase-order-form-field-select {
    width: 15em;    
    padding: 0.2em;
}

</style>
<script lang="js" src="./PurchaseOrderForm.js" />