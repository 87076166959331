<template>
    <div class="vendor-cdm-list-content">
        <select class="vendor-cdm-list-content-select" 
            v-model="selectedVendorItemId" @change="onVendorItemCdmSelected">
            <option v-for="vendorItem in vendorItems" :key="vendorItem.VendorItemId">{{vendorItem.VendorItemId}}</option>
        </select>
    </div>    
</template>

<style scoped>
.vendor-cdm-list-content {
    display: flex;
    flex-direction: column;
}

.vendor-cdm-list-content-select {
    padding: 0.2em;
}
</style>
<script lang="js" src="./VendorItemCDMsSelector.js" />