import { mapActions, mapGetters } from "vuex"
import PurchaseOrderForm from "../components/purchase-order-gen/PurchaseOrderForm.vue"
import PurchaseOrderLinesEditor from "../components/purchase-order-gen/PurchaseOrderLinesEditor.vue";

import { PurchaseOrder } from "../api/purchase-order"

export default {
    name: "PurchaseOrderGenerator",
    components: {
        PurchaseOrderForm,
        PurchaseOrderLinesEditor
    },
    data() {
        return {
            newPurchaseOrder: new PurchaseOrder(),
            selectedVendorCode: "",
        }
    },
    computed: {
        ...mapGetters('purchaseOrdersGen', 
            { status: 'getStatus', vendorCdms: 'getVendorCDMs', vendorItemCdms: 'getVendorItemCDMs' }
        ),
    },
    watch: {
        newPurchaseOrder: {
            deep: true,
            handler: function(newVal) {
                const newVendorCode = newVal.vendorCode;
                if (newVendorCode != this.selectedVendorCode) {
                    this.selectedVendorCode = newVendorCode;
                }
            }
        },
        selectedVendorCode: function() {
            this.loadVendorItemCDMs(this.selectedVendorCode);
        }
    },
    mounted: function() {
        this.loadVendorCDMs();
    },
    methods: {
        ...mapActions('purchaseOrdersGen', ['createNewPurchaseOrderCDM', 'loadVendorCDMs', 'loadVendorItemCDMs']),
        loadCdmModel() {
            this.createNewPurchaseOrderCDM({purchaseOrderData: this.newPurchaseOrder, generate: true});
        },
        onNewLineAddRequested() {
            this.newPurchaseOrder.lines.push(this.newPurchaseOrder.newLine(
                0, "", "", "", 0, this.newPurchaseOrder.lines.length+1+"", 
                0, false, 0, "", "", (new Date()).toDateString,
                "", "", "", "", ""
            ));
        }
    }

}