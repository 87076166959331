import AddressForm from "../master-data-gen/AddressForm.vue";
import VendorCDMsList from "./VendorCDMsList.vue";

export default {
    name: "PurchaseOrderForm",
    components: {
        AddressForm,
        VendorCDMsList,
    },
    props: [
        'purchaseOrder',
        'vendorCdms'
    ],
    data() {
        return {
        };
    },
    methods: {
        onVendorCdmSelected(vendorCdm) {
            this.purchaseOrder.vendorCode = vendorCdm.VendorCode;
            this.purchaseOrder.organizationCode = vendorCdm.Organization ? vendorCdm.Organization[0].OrganizationCode : "";
        }
    }
}