import VendorItemCDMsSelector from "./VendorItemCDMsSelector.vue";

export default {
    name: "PurchaseOrderLineForm",
    components: {
        VendorItemCDMsSelector
    },
    props: [
        'purchaseOrderLine',
        'selectableVendorItemCdms',
    ],
    data() {
        return {
            selectableItemPurchaseUoms: []
        };
    },
    watch: {
        selectableVendorItemCdms: function() {
            this.clearItemInformation();
        },
        selectableItemPurchaseUoms: function() {
            this.purchaseOrderLine.purchaseUomCode = "";
        }
    },
    methods: {
        onVendorItemCdmSelected(vendorItemCdm) {
            this.purchaseOrderLine.itemCode = vendorItemCdm.ItemCode;
            this.purchaseOrderLine.vendorItemCode = vendorItemCdm.VendorItemCode;
            this.purchaseOrderLine.vendorItemDescription[0].shortDescription = 
                vendorItemCdm.Description && vendorItemCdm.Description[0] && vendorItemCdm.Description[0].ShortDescription 
                ? vendorItemCdm.Description[0].ShortDescription 
                : "";
            this.selectableItemPurchaseUoms = vendorItemCdm.VendorUom ? vendorItemCdm.VendorUom : [];

        },
        clearItemInformation() {
            this.purchaseOrderLine.itemCode = "";
            this.purchaseOrderLine.vendorItemCode = "";
            this.purchaseOrderLine.vendorItemDescription[0].shortDescription = ""; 
            this.purchaseOrderLine.purchaseUomCode = "";
            this.selectableItemPurchaseUoms = [];
        }
    }
}