export function PurchaseOrder() {
    
    this.clear = () => {
        this.purchaseOrderNumber = "",
        this.organizationCode = "",
        this.asnRequired = false,
        this.buyerCode = "",
        this.buyerEmployeeCode = "",
        this.buyerName = "",
        this.canceled = false,
        this.containsSpecialOrder = "",
        this.currencyCode = "",
        this.divisionCode = "",
        this.divisionExternalReference = "",
        this.dropShipment = false,
        this.externalReference = "",
        this.freeOnBoardCode = "",
        this.orderSource = "SUPPLY_ORDER",
        this.paymentTermCode = "",
        this.purchaseOrderDate = "",
        this.purchaseOrderStatus = "NEW",
        this.purchaseOrderType = "REGULAR",
        this.requestedDeliveryDate = "",
        this.shipViaCode = "",
        this.sourceSystemTrusted = false,
        this.splitByReplenishmentGroup = false,
        this.supplySiteAddressCode = "",
        this.VendorCode = "",
        this.VendorExternalReference = "",
        this.instruction = [{
            comment: "", sequence: 1
        }],
        this.supplySiteAddress = {
            address1: "",
            address2: undefined,
            address3: undefined,
            city: "",
            county: "",
            stateOrProvince: "",
            country: "",
            zipOrPostalCode: ""
        }
        this.lines = [],
        this.systemManagedType = "TECSYS"
    }

    this.newLine = (expectedQuantity, generalLedgerGroupCode, itemCode, itemExternalReference, landedCostInStockingUom, lineNumber,
        netUnitCost, netUnitCodeOverriden, purchaseQuantity, purchaseUomCode, replenishmentGroupExternalReference, 
        requestedDeliveryDate, vendorItemCode, vendorItemExternalReference, warehouseCode, warehouseGlobalLocationNumber, 
        vendorItemDescription) => {

            //add validations

            let newLine = new PurchaseOrderLine();
            newLine.expectedQuantity = expectedQuantity;
            newLine.generalLedgerGroupCode = generalLedgerGroupCode;
            newLine.itemCode = itemCode;
            newLine.itemExternalReference = itemExternalReference;
            newLine.landedCostInStockingUom = landedCostInStockingUom;
            newLine.lineNumber = lineNumber;
            newLine.netUnitCost = netUnitCost;
            newLine.netUnitCodeOverriden = netUnitCodeOverriden;
            newLine.purchaseQuantity = purchaseQuantity;
            newLine.purchaseUomCode = purchaseUomCode;
            newLine.replenishmentGroupExternalReference = replenishmentGroupExternalReference;
            newLine.requestedDeliveryDate = requestedDeliveryDate;
            newLine.vendorItemCode = vendorItemCode;
            newLine.vendorItemExternalReference = vendorItemExternalReference;
            newLine.warehouseCode = warehouseCode;
            newLine.warehouseGlobalLocationNumber = warehouseGlobalLocationNumber;
            newLine.vendorItemDescription[0].shortDescription = vendorItemDescription;
            
            return newLine;
        }

    this.init = () => {
        this.clear()
    }

    this.init()
}   

function PurchaseOrderLine() {
    this.expectedQuantity = 0;
    this.generalLedgerGroupCode = "";
    this.itemCode = "";
    this.itemExternalReference = "";
    this.landedCostInStockingUom = 0;
    this.lineNumber = 0;
    this.netUnitCost = 0;
    this.netUnitCodeOverriden = false;
    this.purchaseQuantity = 0;
    this.purchaseUomCode = "";
    this.replenishmentGroupExternalReference = "";
    this.requestedDeliveryDate = "";
    this.vendorItemCode = "";
    this.vendorItemExternalReference = "";
    this.warehouseCode = "";
    this.warehouseGlobalLocationNumber = "";
    this.vendorItemDescription = [{
        shortDescription: ""
    }]
} 