<template>
    <div class="address-form-content">
        <h4>{{addressTitle}}</h4>
        <div class="address-form-field">
            <span>Address1</span>
            <input type="text" class="address-form-field-textbox"
                v-model="address.address1"
                />
        </div>
        <div class="address-form-field">
            <span>Address2</span>
            <input type="text" class="address-form-field-textbox" 
                v-model="address.address2"
                />
        </div>
        <div class="address-form-field">
            <span>Address3</span>
            <input type="text" class="address-form-field-textbox" 
                v-model="address.address3"
                />
        </div>
        <div class="address-form-field">
            <span>City</span>
            <input type="text" class="address-form-field-textbox" 
                v-model="address.city"
                />
        </div>
        <div class="address-form-field">
            <span>County</span>
            <input type="text" class="address-form-field-textbox" 
                v-model="address.county"
                />
        </div>
        <div class="address-form-field">
            <span>State/Province</span>
            <input type="text" class="address-form-field-textbox" 
                v-model="address.stateOrProvince"
                />
        </div>
        <div class="address-form-field">
            <span>Country</span>
            <input type="text" class="address-form-field-textbox" 
                v-model="address.country"
                />
        </div>
        <div class="address-form-field">
            <span>Zip/Postal Code</span>
            <input type="text" class="address-form-field-textbox" 
                v-model="address.zipOrPostalCode"
                />
        </div>        
    </div>    
</template>

<style scoped>
.address-form-content {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.address-form-field {
    display: flex;
    flex-direction: row;
    padding: 0.1em;
}

.address-form-field span {
    width: 30%;
    text-align: left;
}

.address-form-field-checkbox {
    width: auto;    
}

.address-form-field-textbox {
    width: 30em;    
}

</style>
<script lang="js" src="./AddressForm.js" />