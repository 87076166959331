
export default {
    name: "AddressForm",
    props: [
        'address',
        'title'
    ],
    computed: {
        addressTitle: function() {
            return this.title ? this.title : "Address";
        }
    },
    methods: {
    }
}